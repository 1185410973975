import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = false;

  constructor(public loadingController: LoadingController) {}

  async presentControlPanelLoader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-control-panel-loader-css',
      })
      .then((res) => {
        res.present().then(() => {
          if (!this.isLoading) {
            res.dismiss().then();
          }
        });
      });
  }

  async presentControlPanelSearchLoader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-control-panel-search-loader-css',
      })
      .then((res) => {
        res.present().then(() => {
          if (!this.isLoading) {
            res.dismiss().then();
          }
        });
      });
  }

  async presentAdminCommunicatorLoader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-administrator-communicator-loader-css',
      })
      .then((res) => {
        res.present().then(() => {
          if (!this.isLoading) {
            res.dismiss().then();
          }
        });
      });
  }

  async presentInsightsLoader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-loader-insights-css',
      })
      .then((res) => {
        res.present().then(() => {
          if (this.isLoading === false) {
            res.dismiss().then();
          }
        });
      });
  }
  
  async presentInsightsLoaderComm() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-loader-insights-css-comm',
      })
      .then((res) => {
        res.present().then(() => {
          if (this.isLoading === false) {
            res.dismiss().then();
          }
        });
      });
  }

  async presentSelectedMenuLoader() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: 'Loading...',
        cssClass: 'my-loader-selected-menu-css',
      })
      .then((res) => {
        res.present().then(() => {
          if (this.isLoading === false) {
            res.dismiss().then();
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;
    this.loadingController
      .dismiss()
      .then(() => {})
      .catch((err) => {});
  }

  status = (): boolean => {
    return this.isLoading;
  };
}
