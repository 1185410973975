import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { RouteReuseStrategy } from '@angular/router';
import { QuillModule, QuillModules } from 'ngx-quill';

const modules: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['link', 'image', 'video'],
  ],

};

@NgModule({
  declarations: [AppComponent],

  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    QuillModule.forRoot({
      placeholder: 'Email body goes here...',
      theme: 'snow',
      format: 'html',
      modules,
    }),
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
