import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/core/models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule],
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

 private user: User | null = null;
  
  
  private loginForm: UntypedFormGroup;
  private verificationForm: UntypedFormGroup;
  
  private title: string;
  private render:boolean = false;
  private showEmail: boolean = true;
  private spinner: boolean = false;
  
  @ViewChild('loginFocus') set emailFocus(element) {
    if (element) {
      element?.setFocus();
    }
  }
  
  @ViewChild('verify') set verifyFocus(element) {
    if (element) {
      element?.setFocus();
    }
  }

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toast: ToastNotificationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.verificationForm = this.fb.group({
      verification: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  ngOnInit() {
    this.checkLoginStatus();
  }
  
  onEmailLogin(): void {
    this.spinner = true;
    const email: string = this.loginForm.value.email.trim();
    if (!email.endsWith('@mail.fresnostate.edu') && !email.endsWith('@csufresno.edu')) {
      this.spinner = false;
      this.toast.presentToastError('Please enter a valid email.');
      return;
    }
    
    this.apiService.getOTP(email)
        .pipe(finalize(() => this.spinner = false))
        .subscribe(
          (res) => {
            this.toast.presentToastSuccess(`A verification code has been sent to <strong>${this.loginForm.value.email}</strong>`);
            this.showEmail = false;
          },
          (error) => {
            this.toast.presentToastError('An error has occured in sending verification code.');
          }
        );
  }


  onVerificationLogin(): void {
    let email: string = this.loginForm.value.email.trim();
    let verificationCode: string = this.stringToNumber(this.verificationForm.value.verification);
    
    this.spinner = true;
    this.apiService.login(email, verificationCode)
        .pipe(finalize(() => this.spinner = false))
        .subscribe(
          (res) => {
            this.user = res.user;
            this.toast.presentToastSuccess('Login sucessful, welcome to MVP.');
            this.navigateUser();
          },
          (error) => {
            this.toast.presentToastError('Login failed, the email or verification code is incorrect.');
          }
        );
  }

  navigateUser(): void {
    const isLoggedIn = this.apiService.isLoggedIn$.subscribe((loggedIn) => loggedIn);
    const roles = this.user.roles.filter(role => role != "RECIPIENT");
    const hasAdminRoles:boolean = this.user.roles.includes('ADMINISTRATOR') || this.user.roles.includes('COMMUNICATOR') || this.user.roles.includes('SUPER_ADMIN');
    const isAdmin: boolean = isLoggedIn && hasAdminRoles;
    
    if(roles.length > 1){
      this.router.navigate(['../welcome'], {replaceUrl:true, relativeTo: this.activatedRoute});
      return;
    }
    
    if(roles.length === 1 && isAdmin && hasAdminRoles){
        if(roles[0] === 'ADMINISTRATOR'){
          this.router.navigate(['../administrator'], {replaceUrl:true, relativeTo: this.activatedRoute});
          return;
        }
      
        if(roles[0] === 'COMMUNICATOR'){
          this.router.navigate(['../communicator'], {replaceUrl:true, relativeTo: this.activatedRoute});
          return;
        }
        
        if(roles[0] === 'SUPER_ADMIN'){
          this.router.navigate(['../su-administrator'], {replaceUrl:true, relativeTo: this.activatedRoute});
          return;
        }
          
    }
    
    else {
        this.router.navigate(['/authorize-error'], { replaceUrl: true });
      }
  }

  onBack(): void {
    this.showEmail = true;
    this.verificationForm?.reset();
    this.spinner = false;
  }

  checkLoginStatus() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
        this.navigateUser();
      } 
      else {
        this.apiService.logout();
        this.render = true;
      }
    });
  }
  
  stringToNumber = (num: number) : string => {
    let newNum = num.toString();
    while(newNum.length < 6) {
      newNum = "0" + newNum;
    }
    
    return newNum
  }
}
