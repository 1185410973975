import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor() {}

  writeChannelCookie:Function = (id, cookie):void => {
    if (id && cookie) {
      localStorage.setItem(id, cookie);
    }
  }

  readChannelCookie:Function = (id): string => {
    return localStorage.getItem(id);
  }

  deleteChannelCookie:Function = (id): void => {
    if (id) {
      localStorage.removeItem(id);
    }
  }
}