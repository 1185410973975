import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { retry, catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   return next.handle(request)
     .pipe(
        catchError((error: HttpErrorResponse) => {
            let errorMessage;

         if (error.error instanceof ErrorEvent) {

           errorMessage = error
           console.log(errorMessage)

         } else {

           // server-side error

           errorMessage = error
            console.log(errorMessage)

         }

         return throwError(errorMessage);

       })

     )

 }

}