import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { ApiService } from './services/api.service';
import { CookiesService } from './services/cookies.service';
import { PopoverService } from './services/popover-service.service';
import { ToastNotificationService } from './services/toast-notification.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { ConvertTierPipe } from '../shared/pipes/convert-tier.pipe';

@NgModule({
  declarations: [],
  imports: [ConvertTierPipe, DateAgoPipe],
  exports: [
    ConvertTierPipe,
    DateAgoPipe,
    HttpErrorInterceptor,
    LoaderService,
    ApiService,
    CookiesService,
    PopoverService,
    ToastNotificationService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
