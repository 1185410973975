import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginPageComponent } from './features/login/pages/login-page/login-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'login',
    pathMatch: 'full'
  },
  
  {
    path: 'login',
    // loadChildren: () => import('./features/login/login-routing.module').then(m => m.LoginRoutingModule)
    component:LoginPageComponent
  },
  
  {
    path: 'communicator',
    canActivate:[AuthGuard],
    data:{
      role: "COMMUNICATOR"
    },
    loadChildren: () => import('./features/communicator/communicator.module').then(m => m.CommunicatorModule)
  },
  
  {
    path: 'administrator',
    canActivate:[AuthGuard],
    data:{
      role: "ADMINISTRATOR"
    },
    loadChildren: () => import('./features/administrator/administrator.module').then(m => m.AdministratorModule)
  },
  
  {
    path: 'su-administrator',
    canActivate:[AuthGuard],
    data:{
      role: "SUPER_ADMIN"
    },
    loadChildren: () => import('./features/super-administrator/super-administrator.module').then(m => m.SuperAdministratorModule)
  },
  
  {
    path: 'welcome',
    canActivate:[AuthGuard],
    loadChildren: () => import('./features/welcome/welcome-routing.module').then(m => m.WelcomeRoutingModule)
  },
  
  {
    path: 'authorize-error',
    loadChildren: () => import('./features/not-authorized/not-authorized-routing.module').then(m => m.NotAuthorizedRoutingModule)
  },
  
  {
  path: '**',
  loadChildren: () => import('./features/not-found/not-found-routing.module').then(m => m.NotFoundRoutingModule)
  }
];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
