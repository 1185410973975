import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';
import { ApiService } from 'src/app/core/services/api.service';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ThrottleClickDirective } from '../../directives/throttle-click.directive';

@Component({
  selector: 'app-user-pop-over',
  templateUrl: './user-pop-over.component.html',
  styleUrls: ['./user-pop-over.component.scss'],
  standalone: true,
  imports:[CommonModule, IonicModule, ThrottleClickDirective]
})
export class UserPopOverComponent implements OnInit {
  private user: User | null;
  private isClicked: boolean = false;

  constructor(
    private router: Router,
    private toast: ToastNotificationService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
  ) {}
  
  ngOnInit() {
    this.apiService.user$.pipe(take(1)).subscribe((user) => {
      if (user !== null && !this.apiService.tokenExpired()) {
        this.user = user;
      } 
      else {
        this.navigateToLogin();
      }
    });
  }
  
  checkRoles = (): boolean => {
    const userRoles = this.user.roles.filter((role) => {
      return role !== 'RECIPIENT';
    })
    return this.user && userRoles.length > 1
  }

  onSwitchRole = (): void => {
    if (this.checkRoles()) {
      this.router.navigate(['/welcome']);
    }
  }
  
  navigateToLogin = () =>  {
    this.router.navigate(['/login'], { replaceUrl: true });
  }
  
  onLogout= ():void => {
    this.isClicked = true
    this.apiService.userLogout(this.user.email)
        .subscribe(
          res => {
              this.apiService.logout();
              this.navigateToLogin();
              this.toast.presentToastSuccess('Logged out successfully.');
          },
          
          error => {
            if(error !== 401){
              this.toast.presentToastError('An error has occured.');
            }
          }
        )
  }
  
  onUser = ():void => {
    
  }
  
  // enter ev trigger click, dismisses popup
  triggerClick = (eve):void => {
    eve.target.click();
  }
}
